<script setup>
  import { ref, onBeforeMount, onMounted, onUnmounted, watch, computed } from 'vue';
  import { useForm } from '@tanstack/vue-form';
  import AddressesDialog from "../../../components/Utility/Dialogs/Addresses.vue";

  import { useAddressesStore } from "../../../stores/addresses";

  import { useDialog } from "../../../composables/useDialog.js";
  import { useSetNewSwipers } from '../../../composables/useSetNewSwipers.js';
  import { validatePhoneNumber } from '../../../includes/validatePhoneNumber.js';
	import RecaptchaField from "../../../components/Utility/RecaptchaField.vue";

  const setNewSwipers = useSetNewSwipers();
  const buttonLoading = ref(false);

  const props = defineProps({
    data: Object
  });

  sendDataLayers('begin payment', {});

  const defaultBillingAddress = computed(() => useAddressesStore().defaultBillingAddress);
  const defaultBillingAddressRemark = ref(checkoutExistingAddressTextCode);

  const emit = defineEmits(['success']);

  const formAddresses = ref({
    shipping: null,
    billing: null,
  });

  const sameAsShipping = ref(true);

  const initializedSwipers = ref(false);

  const addressesError = ref(false);

  const form = useForm({
    defaultValues: {
      phone_number: props.data?.user?.phone_number ?? '',
      track_and_trace_email: props.data?.user?.email_address ?? '',
      invoice_number: '',
    },
    onSubmit: async ({ value }) => {
      if(formAddresses.value.shipping === null) {
        addressesError.value = true;
        scrollElmView('.form-error', 250);
        return;
      } else {
        addressesError.value = false;
      }

      if (sameAsShipping.value) {
        formAddresses.value.billing = formAddresses.value.shipping;
      }

      const data = {
        phone_number: value.phone_number,
        addresses: formAddresses.value,
        track_and_trace_email: value.track_and_trace_email,
        invoice_number: value.invoice_number,
        recaptcha_response: recaptchaToken.value,
      }
      buttonLoading.value = true;

      fetch('/api/checkout/address', {
        method: 'POST',
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          sendDataLayers('shipping address', {});
          emit('success',true);
          buttonLoading.value = false;
        } else {
          // Handle error
          buttonLoading.value = false;
          if (response.message) {
            error = true;
          } else {
            return;
          }
          captcha.value.refresh();
        }
      })
      .catch(error => {
        // Handle error
		    captcha.value.refresh();
      });
    }
  });

  const setAddress = (id, type) => {
    if (formAddresses.value[type]?.id !== id) {
      formAddresses.value[type] = { id };
    }
    if (props.data.cart.shipping_method.is_pickup) {
      formAddresses.value.billing = { id };
    }
  }

  const openAddressesDialog = async () => {
    const isMobile = ref(false);

    isMobile.value = window.innerWidth < 768;

    if (isMobile.value) {
      useDialog().setPosition('left');
    } else {
      useDialog().setPosition('top');
    }

    useDialog().open(AddressesDialog, {'cartAddresses': formAddresses, 'addresses': useAddressesStore().addresses, 'countries': useAddressesStore().countries});
  };

  const findCountryById = (country_id) => {
    return props.data.countries.find((country) => country.id == country_id);
  }

  onBeforeMount(async () => {
    useAddressesStore().fillPrimaryStores();
    useAddressesStore().fillCountries();
    setNewSwipers();
  });

  onMounted(() => {
    if (props.data.cart.shipping_address?.external_id) {
      formAddresses.value.shipping = {"id" : props.data.cart.shipping_address?.external_id};
    }

    if (props.data.cart.billing_address?.external_id) {
      formAddresses.value.billing = {"id" : props.data.cart.billing_address?.external_id};
    }

    if (props.data.cart.billing_address?.external_id !== props.data.cart.shipping_address?.external_id) {
      sameAsShipping.value = false;
    }

    setNewSwipers();
    window.addEventListener('resize', handleResize);
  });

  const handleResize = () => {
    setNewSwipers();
  };

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });

  watch(
    () => useAddressesStore()?.isLoggedIn,
    (newVal) => {
      if (newVal) {
        setNewSwipers();
      }
    }
  );

  watch(
    () => useAddressesStore()?.isAddressesStoreFilled,
    (newVal) => {
      if (newVal) {
        setNewSwipers();
      }
    }
  );

  const recaptchaToken = ref(null);
  const captcha = ref(null);

  /* TEXTUAL ELEMENTS */
  const loggedInTitleText = ref(loggedInTitleTextCode);
  const billingLabelText = ref(billingLabelTextCode);
  const billingSameAsShippingText = ref(billingSameAsShippingTextCode);
  const contactDataTitleText = ref(contactDataTitleTextCode);
  const phoneLabelText = ref(phoneLabelTextCode);
  const trackTraceLabelText = ref(trackTraceLabelTextCode);
  const invoiceLabelText = ref(invoiceLabelTextCode);
  const submitButtonText = ref(submitButtonTextCode);
  const mailErrorText = ref(mailErrorTextCode);
  const genericErrorText = ref(genericErrorTextCode);
  const obligatoryFieldText = ref(obligatoryFieldTextCode);
</script>
<template>
  <section class="checkout-existing-address" aria-label="Vul hier je gegevens in">
    <span class="checkout-existing-address__title" v-if="!props.data.cart.shipping_method.is_pickup">
      {{ loggedInTitleText }} <span v-if="addressesError" class="form-error">{{ obligatoryFieldText }}</span> <!-- VUE_CO_EA_LOGGEDIN_TITLE -->
    </span>
    <span class="checkout-existing-address__title" v-else>
      {{ billingLabelText }} <span v-if="addressesError" class="form-error">{{ obligatoryFieldText }}</span> <!-- VUE_CO_EA_LOGGEDIN_TITLE -->
    </span>
    <div class="slider slider--shipping-addresses" v-if="useAddressesStore()?.isAddressesStoreFilled === true && useAddressesStore()?.isLoggedIn === true">
      <div class="slider__items">
          <div class="slider__swiper swiper swiper--buttons swiper--vue" :class="{ 'swiper--slide-active' : useAddressesStore()?.addresses.length > 2 }">
              <div class="slider__swiper-wrapper swiper-wrapper">
                  <div class="swiper-slide" v-for="(address, index) in Array.isArray(useAddressesStore()?.addresses) ? useAddressesStore()?.addresses : []" :key="index" :class="{'active-slide' : address.id === formAddresses?.shipping?.id}" :data-nr="index">
                    <div class="address__content"
                          @click="setAddress(address.id, 'shipping')"
                          :class="{'address__content--active': address.id === formAddresses?.shipping?.id}"
                    >
                      <div class="address__inner">
                        <strong class="notranslate">{{ address.title }}</strong>
                        <span class="notranslate">{{ address.first_name }} {{ address.last_name }}</span>
                        <span class="notranslate">{{ address.street }} {{ address.house_number }} {{ address.house_number_addition }}</span>
                        <span class="notranslate">{{ address.zip }} {{ address.city }}</span>
                        <span v-if="address?.country_id">{{ findCountryById(address.country_id).name }}</span>
                        <span v-else-if="address?.country">{{ address.country }}</span>
                      </div>
                    </div>
                  </div>
              </div>
            <div class="slider__buttons swiper__buttons">
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
          </div>
      </div>
    </div>
    <div class="slider--skeleton" v-else><!-- --></div>
    <button class="button__change-address btn-text--primary-600" @click="openAddressesDialog(useAddressesStore().addresses)" v-if="useAddressesStore()?.isAddressesStoreFilled === true && useAddressesStore()?.isLoggedIn === true">Wijzig of voeg nieuw adres toe</button>
    <span class="button--skeleton" v-else><!-- --></span>
    <span class="slider--skeleton" v-if="useAddressesStore()?.isAddressesStoreFilled === false && props.data?.invoiceMutable === true"><!-- --></span>
    <div class="input input--single" v-if="useAddressesStore()?.isAddressesStoreFilled === true && props.data?.invoiceMutable === true && !props.data.cart.shipping_method.is_pickup">
      <div class="input-inner">
        <span for="invoice_same_as_shipping" class="form__label">{{ billingLabelText }}</span> <!-- VUE_CO_EA_BILLING_LABEL -->
        <div class="input-inner__checkbox">
          <label for="invoice_same_as_shipping" class="input-container">
            <form.Field name="invoice_same_as_shipping">
              <template v-slot="{ field }">
                <input
                  type="checkbox"
                  :name="field.name"
                  :value="field.state.value"
                  :checked="sameAsShipping"
                  @blur="field.handleBlur"
                  id="invoice_same_as_shipping"
                  @input="field.handleChange($event.target.checked); sameAsShipping = $event.target.checked"
                  class="checkbox__fix-input"
                >
                <span class="checkbox__fix-checkbox"></span>
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
            {{ billingSameAsShippingText }}</label> <!-- VUE_CO_EA_BILLING_SAME_AS_SHIPPING -->
        </div>
      </div>
    </div>
    <div class="slider slider--billing-addresses" :class="{'slider--inactive' : sameAsShipping}" v-if="useAddressesStore()?.isAddressesStoreFilled === true && props.data?.invoiceMutable === true && !props.data.cart.shipping_method.is_pickup">
      <div class="slider__items">
          <div class="slider__swiper swiper swiper--buttons swiper--vue" :class="{ 'swiper--slide-active' : useAddressesStore()?.addresses.length > 2 }">
              <div class="slider__swiper-wrapper swiper-wrapper">
                  <div class="swiper-slide" v-for="(address, index) in useAddressesStore()?.addresses" :key="index" :class="{'active-slide' : address.id === formAddresses?.shipping?.id}" :data-nr="index">
                    <div class="address__content"
                          @click="setAddress(address.id, 'billing')"
                          :class="{'address__content--active': address.id === formAddresses?.billing?.id}"
                    >
                      <div class="address__inner">
                        <strong class="notranslate">{{ address.title }}</strong>
                        <span class="notranslate">{{ address.first_name }} {{ address.last_name }}</span>
                        <span class="notranslate">{{ address.street }} {{ address.house_number }} {{ address.house_number_addition }}</span>
                        <span class="notranslate">{{ address.zip }} {{ address.city }}</span>
                        <span>{{ address.country }}</span>
                      </div>
                    </div>
                  </div>
              </div>
            <div class="slider__buttons swiper__buttons">
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
          </div>
      </div>
    </div>
    <div class="default__billing" v-else-if="props.data?.invoiceMutable === false && useAddressesStore()?.isAddressesStoreFilled === true">
      <span class="checkout-existing-address__title">
        {{ billingLabelText }} <!-- VUE_CO_EA_BILLING_LABEL -->
      </span>
      <div class="default__billing-inner">
        <div class="address__content">
          <div class="address__inner">
              <strong class="notranslate">{{ defaultBillingAddress.title }}</strong>
              <span class="notranslate">{{ defaultBillingAddress.first_name }} {{ defaultBillingAddress.last_name }}</span>
              <span class="notranslate">{{ defaultBillingAddress.street }} {{ defaultBillingAddress.house_number }} {{ defaultBillingAddress.house_number_addition }}</span>
              <span class="notranslate">{{ defaultBillingAddress.zip }} {{ defaultBillingAddress.city }}</span>
              <span>{{ defaultBillingAddress.country }}</span>
          </div>
        </div>
      </div>
      <span class="default__billing-remark" v-if="defaultBillingAddressRemark">{{ defaultBillingAddressRemark }}</span>
    </div>
    <span class="checkout-existing-address__title checkout--phonenumber">
      {{ contactDataTitleText }} <!-- VUE_CO_EA_CONTACTDATA_TITLE -->
    </span>
    <div class="form__wrapper">
      <form @submit="
      (e) => {
        e.stopPropagation()
        e.preventDefault()
        form.validate()
        form.handleSubmit()
      }
      "
      method="post"
      >
        <div class="input input--single input--full">
          <div class="input-inner" v-if="useAddressesStore()?.isAddressesStoreFilled === true">
            <label for="phone_number" class="form__label">{{ phoneLabelText }}</label>
            <form.Field
              name="phone_number"
              :validators="{
                onBlur: ({ value }) => {
                  switch (true) {
                  case !validatePhoneNumber(value):
                    return 'Ongeldig telefoonnummer';
                  default:
                    return null;
                  }
                },
              }"
            >
              <template v-slot="{ field }">
                <input
                type="tel"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value.replace(/[^0-9+\-()]+/g, ''))"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                inputmode="numeric"                 
                required
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner" v-else>
            <span class="form-label--skeleton"><!-- --></span>
            <span class="form-input--skeleton"><!-- --></span>
          </div>
        </div>
        <div class="input input--single">
          <div class="input-inner" v-if="useAddressesStore()?.isAddressesStoreFilled === true">
            <label for="track_and_trace_email" class="form__label">{{ trackTraceLabelText }}</label> <!-- VUE_CO_EA_TRACK_TRACE_LABEL -->
            <form.Field name="track_and_trace_email">
              <template v-slot="{ field }">
                <input
                type="email"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner" v-else>
            <span class="form-label--skeleton"><!-- --></span>
            <span class="form-input--skeleton"><!-- --></span>
          </div>
        </div>
        <div class="input input--single">
          <div class="input-inner" v-if="useAddressesStore()?.isAddressesStoreFilled === true">
            <label for="invoice_number" class="form__label">{{ invoiceLabelText }}</label> <!-- VUE_CO_EA_INVOICE_LABEL -->
            <form.Field name="invoice_number">
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner" v-else>
            <span class="form-label--skeleton"><!-- --></span>
            <span class="form-input--skeleton"><!-- --></span>
          </div>
        </div>
        <div class="form__submit form__submit--single">
          <div class="form__submit-inner">
            <div class="form__submit-button">
              <button class="btn-solid--primary-600" type="submit">
                {{ submitButtonText }}
              </button>
              <div class="form__submit-loading" v-if="buttonLoading">
                <i class="fa-solid fa-spinner"></i>
              </div>
            </div>
          </div>
        </div>
        <RecaptchaField ref="captcha" v-model="recaptchaToken"></RecaptchaField>
      </form>
    </div>
  </section>
</template>


<style lang="scss" scoped>
	@import '../../../../sass/elements/skeletons.scss';

  .slider--skeleton {
    @apply h-[162px] w-full mt-3;
    @extend .skeleton;
  }

  .address--skeleton {
    @apply h-4 w-[140px];
    @extend .skeleton;

    + .address--skeleton {
      @apply mt-3;
    }
  }

  .default__billing {
    .default__billing-remark {
      @apply text-text text-13_18_11_14;
      @apply xs:leading-[12px];
    }
  }

  .default__billing-inner {
    @apply max-w-[340px] pt-3;
    @apply sm:pt-1.5;
  }

  .form-label--skeleton {
    @apply h-5 w-[100px] mb-2;
    @extend .skeleton;
  }

  .form-input--skeleton {
    @apply h-[51px] w-[390px];
    @extend .skeleton;
  }

  .button--skeleton {
    @apply h-5 w-[200px] mt-3 mb-9;
    @extend .skeleton;
  }

  .checkout-existing-address {
      @apply col-span-8;
      @apply md:col-span-12;
  }

  .checkout-existing-address__title {
      @apply font-bold text-24_28_18_26 text-dark col-span-12 block;

      &--small {
        @apply text-17_21_14_21;
      }

      span {
        @apply text-13_18_11_14 text-[#ef4444] font-normal;
      }
  }

  .form__label {
      span {
        @apply text-13_18_11_14 text-[#ef4444] font-normal;
      }
  }

  .form-error {
     @apply text-13_18_11_14 text-[#ef4444] font-normal relative;
  }

  .address__content {
    @apply bg-white py-8 px-10 rounded border border-solid border-border transition-all duration-300 cursor-pointer h-full text-16_28_14_24;
    @apply sm:p-4;
    @apply hover:border-[#CCA666];

    &--active {
      @apply bg-[#FAF2E6] border-[#CCA666];
    }
  }

  .address__inner {
    @apply flex flex-col;
  }

  .button__change-address {
    @apply font-normal text-15_20_14_20 ;
    @apply md:mb-2;

    &:has(~.slider--billing-addresses) {
      @apply mb-9;
    }

    +.default__billing {
      @apply mt-9;
      @apply mob:mt-4;
    }
  }

  .form__wrapper {
    @apply mt-3 grid grid-cols-12;

    .input--single {
      @apply col-span-12 grid grid-cols-12;

      .input-inner {
        @apply col-span-6;
        @apply sm:col-span-12;
      }
    }

    form {
      @apply col-span-12 grid grid-cols-12;
    }
  }

  .form__submit {
    @apply border-t border-[#E0E0E0] border-solid col-span-12 pt-[30px] mt-12;
    @apply md:mt-6;
    @apply sm:border-none sm:pt-0;
    @apply mob:mt-[18px];
  }

  .form__submit--single {
    .form__submit-inner {
      @apply justify-end;
    }

    .form__submit-button {
      @apply max-w-max;
    }
  }

  .form__submit-inner {
    @apply flex justify-between items-center;
    @apply sm:flex-col sm:items-start;
  }

  .checkout--phonenumber {
    @apply mt-12;
    @apply md:mt-[18px];
  }

  .input--same-as-shipping {
    @apply py-3;
    @apply md:pt-1;
  }

  .form__submit-button {
    @apply w-full flex justify-end;
    @apply mob:w-full;

    button {
      @apply cursor-pointer;

      &.disabled {
        @apply pointer-events-none;
      }
    }
  }

  .slider--inactive {
    @apply hidden;
  }

  .input {
    + .input {
      @apply mt-3;
      @apply sm:mb-0;
    }
  }
</style>
