<script setup>
  import { ref } from "vue";
  import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

  const props = defineProps({
    data: Object
  });

  const emit = defineEmits(['back', 'go']);

  const loading = ref(false); /* HARDCODED SKELETON LOADING */

  const orderOverviewTitleText = ref(orderOverviewTitleTextCode);
  const paymentLinkButtonText = props.data.cart.payment_method.is_by_invoice ? ref(paymentLinkButtonTextCodeFinish) : ref(paymentLinkButtonTextCode);
  const addressTitleText = ref(addressTitleTextCode);
  const editAddressButtonText = ref(editAddressButtonTextCode);
  const contactTitleText = ref(contactTitleTextCode);
  const deliveryTitleText = ref(deliveryTitleTextCode);
  const editDeliveryButtonText = ref(editDeliveryButtonTextCode);
  const paymentTitleText = ref(paymentTitleTextCode);
  const editPaymentButtonText = ref(editPaymentButtonTextCode);
  const backButtonText = ref(backButtonTextCode);

  const billingLabelText = ref(billingLabelTextCode);
</script>

<template>
  <section class="overview">

    <div class="row">
      <div class="title" v-if="!loading">
        {{ orderOverviewTitleText }}
      </div>
      <span class="title--skeleton" v-else>
        <!-- -->
      </span>

      <div class="button" v-if="!loading">
        <a :href="data.payment_link" class="btn-solid--primary-600">{{ paymentLinkButtonText }}</a>
      </div>
      <span class="button--skeleton" v-else>
        <!-- -->
      </span>
    </div>

    <div class="overview__entry">
      <span class="overview__entry-title" v-if="!props.data.cart.shipping_method.is_pickup">{{ addressTitleText }}</span>
      <span class="overview__entry-title" v-else>{{ billingLabelText }}</span>
      <div class="overview__entry-data" v-if="!loading">
        <div class="name">{{ data.cart.billing_address.first_name }} {{ data.cart.billing_address.last_name }}</div>
        <div class="ifo">
          {{ data.cart.billing_address.street }} {{ data.cart.billing_address.house_number }} {{
          data.cart.billing_address.house_number_addition }} <br />
          {{ data.cart.billing_address.postal_code }} {{ data.cart.billing_address.city }}

          {{ data.cart.billing_address.country }}
        </div>

        <a @click.prevent="emit('go', '/api/checkout/address')" class="btn-text-primary-600 link">{{ editAddressButtonText }}</a>
      </div>
      <div class="overview__entry-data" v-else>
        <span class="skeleton__text"><!-- --></span>
        <span class="skeleton__text"><!-- --></span>
        <span class="skeleton__text"><!-- --></span>
        <span class="skeleton__text"><!-- --></span>
        <span class="skeleton__button"><!-- --></span>
      </div>
    </div>

    <div class="overview__entry">
      <span class="overview__entry-title">{{ contactTitleText }}</span> 
      <div class="overview__entry-data" v-if="!loading">
        <span class="overview__entry-data__item">
          {{ data.cart.billing_address.phone_number }}
        </span>
        <span class="overview__entry-data__item">
          {{ data.cart.billing_address.email_address }}
        </span>
      </div>
      <div class="overview__entry-data" v-else>
        <span class="skeleton__text"><!-- --></span>
        <span class="skeleton__text"><!-- --></span>
      </div>
    </div>

    <div class="overview__entry overview__entry--single" v-if="!loading">
      <span class="overview__entry-title">{{ deliveryTitleText }}</span> <!-- VUE_CO_OVERV_DELIVERY_TITLE -->
      <div class="overview__entry-data">
        <span class="overview__entry-data__item">
          {{ data.cart.shipping_method.title }}
        </span>
        <a :href="appendLanguagePrefixURL('/winkelwagen')" class="btn-text-primary-600 link">{{ editDeliveryButtonText }}</a> <!-- VUE_CO_OVERV_BUTTON_EDIT_DELIVERY -->
      </div>
    </div>
    <div class="overview__entry overview__entry--single" v-else>
      <span class="overview__entry-title">{{ deliveryTitleText }}</span> <!-- VUE_CO_OVERV_DELIVERY_TITLE -->
      <div class="overview__entry-data">
        <span class="skeleton__text"><!-- --></span>
        <span class="skeleton__button"><!-- --></span>
      </div>
    </div>

    <div class="overview__entry overview__entry--single" v-if="!loading">
      <span class="overview__entry-title">{{ paymentTitleText }}</span>
      <div class="overview__entry-data">
        <span class="overview__entry-data__item">
          {{ data.cart.payment_method.title }}
          <span v-if="data.cart.payment_method.meta.issuer?.title">
            ({{ data.cart.payment_method.meta.issuer.title }})
          </span>
        </span>
        <a @click.prevent="emit('back')" class="btn-text-primary-600 link">{{ editPaymentButtonText }}</a>
      </div>
    </div>
    <div class="overview__entry overview__entry--single" v-else>
      <span class="overview__entry-title">{{ deliveryTitleText }}</span>
      <div class="overview__entry-data">
        <span class="skeleton__text"><!-- --></span>
        <span class="skeleton__button"><!-- --></span>
      </div>
    </div>

    <div class="navigation" v-if="!loading">
      <div>
        <a @click.prevent="emit('back')" class="btn-text-primary-600 navigation--back link">{{ backButtonText }}</a>
      </div>
      <div>
        <a :href="data.payment_link" class="btn-solid--primary-600" >{{ paymentLinkButtonText }}</a>
      </div>
    </div>
    <div class="navigation" v-else>
        <span class="navigiaton-back--skeleton"><!-- --></span>
        <span class="navigiaton-forward--skeleton"><!-- --></span>
    </div>
  </section>
</template>

<style scoped lang="scss">
	@import '../../../../sass/elements/skeletons.scss';

  .overview {
    @apply col-span-8 grid grid-cols-12 gap-y-10;
    @apply md:col-span-12;

    .row {
      @apply flex justify-between col-span-12 items-center;

      .title {
        @apply text-24_30_17_20 font-fontText font-bold text-dark;
      }
    }
  }

  .overview__entry {
    @apply col-span-6;

    &.overview__entry--single {
      @apply col-span-12;
    }
  }

  .navigation--back {
    @apply hover:cursor-pointer;
  }

  .overview__entry-title {
    @apply font-bold font-fontText text-20_30_17_20 mb-3 block;
  }

  .link {
    @apply text-15_36_14_18 text-[#BF8A30] cursor-pointer whitespace-nowrap;
    @apply hover:text-primary-800;
  }

  .navigation {
    @apply flex justify-between items-center col-span-12 gap-3;

    .btn-solid--primary-600 {
      @apply px-[90px];
    }
  }

  .overview__entry-data__item {
    @apply block;
  }

  .skeleton__button,
  .skeleton__text {
    @apply h-[16px] w-[175px];
    @extend .skeleton;

    + .skeleton__text,
    + .skeleton__button {
      @apply mt-4;
    }
  }

  .skeleton__button {
    @apply w-[135px];
  }

  .title--skeleton,
  .navigiaton-back--skeleton {
    @apply h-[20px] w-[105px];
    @extend .skeleton;
  }

  .navigiaton-forward--skeleton {
    @apply h-[54px] w-[336px] inline-flex;
    @extend .skeleton;
  }

  .button--skeleton {
    @apply h-[54px] w-[183px] inline-flex;
    @extend .skeleton;
  }
</style>
